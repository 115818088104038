import { faMobileAlt, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faBuffer } from '@fortawesome/free-brands-svg-icons'

export const usefulLink = [
    {name: 'Home', id: 1},
    {name: 'About us', id: 2},
    {name: 'Services', id: 3},
]
// export const ourServices = [
//     {name: 'Strategy & Research', id: 6},
//     {name: 'Web Design', id: 7},
//     {name: 'Web Development', id: 8},
//     {name: 'Digital Marketing', id: 9},
//     {name: 'Graphic Design', id: 10}
// ]

export const footerInfo = [
    {icon: faBuffer, info1: 'ABADIQ Medical Billing', id: 1},
    {icon: faMobileAlt, info1: '+18002293302', info2: 'info@abadiq.com', id: 2},
];